import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import Slider from 'react-infinite-logo-slider';
import { Helmet } from "react-helmet";

// Assets
import video from './assets/landing_clip.mp4';
import dhcb from "./assets/dhcb.jpeg";
import tuftsMed from "./assets/Tufts-Medicine.png";
import google from "./assets/google.png";
import cmu from "./assets/cmu.jpeg";
import ptc from "./assets/ptc.png";
import cmuAI from "./assets/cmu-ai.png";

// Components
import ContactForm from "./components/Contact/ConatctForm";
import ScrollToHashElement from "@cascadia-code/scroll-to-hash-element";

// Animations
import {
  riseWithFade,
  staggerChildren,
  videoAnimation,
} from "./utils/animations";

// Navigation Links
function goToMyPatients() {
  window.open('https://mypatients.cureva.co', '_blank');
}
function goToClinicalMatch() {
  window.open('https://clinicalmatch.cureva.co', '_blank');
}
function goToLibraries() {
  window.open('https://github.com/Cureva-public', '_blank');
}

// ─────────────────────────────────────────────────────────────────────────────
// Hero Section with Logo Carousel
// ─────────────────────────────────────────────────────────────────────────────
const HeroSection = () => {
  const [currentWord, setCurrentWord] = useState('Better Accuracy');
  const wordsArray = ['Safer', 'Faster', 'Automatic'];

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      index = (index + 1) % wordsArray.length;
      setCurrentWord(wordsArray[index]);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section
      className="hero-section min-h-screen flex flex-col justify-center items-center 
                 bg-gradient-to-b from-orange-50 to-white 
                 border border-gray-200 rounded-lg shadow-lg mx-auto max-w-7xl p-12 text-center 
                 mt-8 mb-16 lg:mt-12 lg:mb-24"
    >
      <motion.div className="w-full max-w-5xl mx-auto px-4">
        <motion.h1
          className="text-6xl font-bold text-gray-800 mb-6"
          variants={staggerChildren}
        >
          Clinical Trials Recruitment using AI
          <motion.span
            className="block text-orange-600"
            key={currentWord}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
          >
            {currentWord}
          </motion.span>
        </motion.h1>
        <motion.p
          className="text-lg text-gray-600 max-w-2xl mx-auto"
          variants={riseWithFade}
        >
          Cureva's AI streamlines recruitment by notifying doctors about trial
          matches during visits, improving outcomes with precision.
        </motion.p>
        <motion.button
          onClick={goToMyPatients}
          className="mt-8 px-6 py-3 bg-yellow-500 text-white rounded-full shadow-lg hover:bg-yellow-500 transition-all"
        >
          Portal for Trial Coordinators
        </motion.button>
      </motion.div>

    </section>
  );
};

// ─────────────────────────────────────────────────────────────────────────────
// Video Section with Description
// ─────────────────────────────────────────────────────────────────────────────
const VideoSection = () => {
  const handleVideoEnded = (event) => {
    setTimeout(() => event.target.play(), 3000); // Restart video with delay
  };

  return (
    <section
      className="flex flex-col md:flex-row justify-center items-center 
                 bg-gradient-to-b from-orange-50 to-white 
                 border border-gray-200 rounded-lg shadow-lg mx-auto max-w-7xl p-12 text-center 
                 mt-16 mb-16 lg:mt-24 lg:mb-24 gap-12"
    >
      <motion.div className="w-full md:w-1/2 space-y-6">
        <h2 className="text-4xl font-bold text-gray-800">
          AI-Powered Trial Recruitment
        </h2>
        <p className="text-lg text-gray-600">
          Our AI automates patient matching, reduces manual effort, and improves
          trial efficiency with seamless integration.
        </p>
        <ul className="space-y-4 text-gray-600">
          <li>✔ Automated patient-matching algorithms</li>
          <li>✔ Integration with clinical trial databases</li>
          <li>✔ Secure data processing</li>
        </ul>
        <motion.button
          onClick={goToMyPatients}
          className="mt-8 px-6 py-3 bg-yellow-500 text-white rounded-full shadow-lg hover:bg-yellow-500 transition-all"
        >
          Sign Up
        </motion.button>
      </motion.div>
    </section>
  );
};

// ─────────────────────────────────────────────────────────────────────────────
// Waitlist Section
// ─────────────────────────────────────────────────────────────────────────────
const WaitlistSection = () => (
  <section
    className="flex flex-col justify-center items-center 
               bg-gradient-to-b from-orange-50 to-white 
               border border-gray-200 rounded-lg shadow-lg mx-auto max-w-7xl p-12 text-center 
               mt-16 mb-16 lg:mt-24 lg:mb-24"
  >
    <h2 className="text-4xl font-bold text-gray-800 mb-6">Join the Waitlist</h2>
    <p className="text-lg text-gray-600 mb-12">
      We are committed to optimizing the clinical trials recruitment process.
      We connect pharmas, CROs, hospital systems, and patients to accelerate
      research and optimize outcomes.
    </p>

    {/* Waitlist Widget */}
    <div
      className="launchlist-widget"
      data-key-id="I0Xs5C"
      data-height="200px"
    ></div>

    {/* Helmet Script for Widget */}
    <Helmet>
      <script
        src="https://getlaunchlist.com/js/widget.js"
        type="text/javascript"
        defer
      />
    </Helmet>
  </section>
);

// ─────────────────────────────────────────────────────────────────────────────
// Integration Section (API + Libraries)
// ─────────────────────────────────────────────────────────────────────────────
const IntegrationSection = () => (
  <section className="my-16 w-full text-center">
    <p className="text-4xl font-bold text-gray-800">
      We are encouraging innovation by making our APIs available
    </p>
    <div
      id="api"
      className="text-center md:flex md:flex-row gap-12 mx-auto mt-12 justify-around md:space-y-0 space-y-6"
    >
      <button
        className="rounded-lg transition duration-500 ease-in-out hover:text-2xl mx-auto md:mx-0 text-3xl text-white bg-yellow-500 w-full md:w-1/3 h-80"
        onClick={goToClinicalMatch}
      >
        Integration API
      </button>
      <button
        className="rounded-lg transition duration-500 ease-in-out hover:text-2xl mx-auto md:mx-0 text-3xl text-white bg-yellow-500 w-full md:w-1/3 h-80"
        onClick={goToLibraries}
      >
        Integration Libraries
      </button>
    </div>
  </section>
);

// ─────────────────────────────────────────────────────────────────────────────
// Contact Section
// ─────────────────────────────────────────────────────────────────────────────
const ContactSection = () => (
  <section
    id="contact"
    className="flex flex-col justify-center items-center 
               bg-gradient-to-b from-orange-50 to-white 
               border border-gray-200 rounded-lg shadow-lg mx-auto max-w-7xl p-12 text-center 
               mt-16 mb-16 lg:mt-24 lg:mb-24"
  >
    <h2 className="text-4xl font-bold text-gray-800 mb-6">Contact Us</h2>
    <p className="text-lg text-gray-600 mb-12">
      We are here to assist you. Reach out to learn more!
    </p>
    <div className="w-full max-w-4xl">
      <ContactForm />
    </div>
  </section>
);

// ─────────────────────────────────────────────────────────────────────────────
// Main App Component
// ─────────────────────────────────────────────────────────────────────────────
function App() {
  return (
    <motion.div className="App" initial="initial" animate="animate">
      <ScrollToHashElement behavior="smooth" />
      <Header />

      <HeroSection />
      <VideoSection />
      <WaitlistSection />
      <IntegrationSection />
      <ContactSection />

      <Footer />
      <Helmet>
        <script
          src="https://getlaunchlist.com/js/widget.js"
          type="text/javascript"
          defer
        />
      </Helmet>
    </motion.div>
  );
}

export default App;
