import { AttachFile } from '@mui/icons-material'
import React from 'react'
import { useState, useEffect } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const today = new Date();
const day = String(today).slice(0, 3);


const ConatctForm = () => {

  const [listOpen, setListOpen] = useState(false);
  const [closed, setClosed] = useState(false);

  useEffect(() => {
    if (day === "Sat" || day === "Sun") {
        setClosed(true);
      } else {
        setClosed(false);
      }
  }, [])

  return (
    <div className="flex flex-col md:flex-row w-full px-8 md:px-20 mt-24">
        <div className="flex flex-col w-full md:w-1/2">
            <p className="text-left text-xl mb-12">Get in Touch</p>
            <input className="border-b border-gray-300 focus:border focus:border-gray-300 p-2 mb-10" placeholder="Name*" id="name" type="text" variant="standard"/>
            <input className="border-b border-gray-300 focus:border focus:border-gray-300 p-2 mb-10" id="email" placeholder="Email*" type="email" variant="standard"/>
            <textarea className="w-full border border-gray-300 p-2 h-[150px]" placeholder='Tell us more about how we can help you'></textarea>
            <label for="doc">
                <div className="flex flex-row justify-between my-8">
                    <div className="flex flex-row">
                        <div className="m-2">
                            <AttachFile className="text-puke hover:cursor-pointer"/>
                        </div>
                        <p className="text-puke my-auto hover:cursor-pointer border-b border-puke">Attach Files</p>
                    </div>
                    <p className="my-auto text-xs">Attachements (0)</p>
                </div>
                <input type="file" id="doc" name="doc" hidden/>
            </label>
            <button className="bg-weird-yellow hover:bg-yellow-500 text-white py-3 px-8 font-expanded font-medium text-xs md:text-xl mt-6 rounded-full">Send</button>
        </div>
        <div className="flex flex-col mt-8 md:mt-0 w-full md:w-1/2 text-left md:pl-12">
            <p className="text-lg mb-6">Questions or Comments?</p>
            <p className="text-gray-500 mb-6">We know that our clients have unique needs. Send us a message, and we will get back to you soon.</p>

            <p className="text-lg mb-6">Cureva</p>
            <p className="text-gray-500 mb-10">Boston, Massachusetts 02113, United States</p>

            {/* <a className="text-puke border-b border-puke w-fit mb-10" href= "mailto: name@email.com"><p>nadim@cureva.co</p></a> */}

            <p className="text-lg mb-6">Hours</p>
            {
                listOpen?
                <div className="flex flex-row">
                    <div className="flex flex-col mr-2">
                        <p className={day === "Mon"? "font-bold" : "text-gray-600"}>Mon</p>
                        <p className={day === "Tue"? "font-bold" : "text-gray-600"}>Tue</p>
                        <p className={day === "Wed"? "font-bold" : "text-gray-600"}>Wed</p>
                        <p className={day === "Thu"? "font-bold" : "text-gray-600"}>Thu</p>
                        <p className={day === "Fri"? "font-bold" : "text-gray-600"}>Fri</p>
                        <p className={day === "Sat"? "font-bold" : "text-gray-600"}>Sat</p>
                        <p className={day === "Sun"? "font-bold" : "text-gray-600"}>Sun</p>
                    </div>
                    <div className="flex flex-col mr-2">
                        <p className={day === "Mon"? "font-bold" : "text-gray-600"}> 06:00 am - 11:00 pm</p>
                        <p className={day === "Tue"? "font-bold" : "text-gray-600"}> 06:00 am - 11:00 pm</p>
                        <p className={day === "Wed"? "font-bold" : "text-gray-600"}> 06:00 am - 11:00 pm</p>
                        <p className={day === "Thu"? "font-bold" : "text-gray-600"}> 06:00 am - 11:00 pm</p>
                        <p className={day === "Fri"? "font-bold" : "text-gray-600"}> 06:00 am - 11:00 pm</p>
                        <p className={day === "Sat"? "font-bold" : "text-gray-600"}> Closed</p>
                        <p className={day === "Sun"? "font-bold" : "text-gray-600"}> Closed</p>
                    </div>
                    <button className="text-puke align-start" onClick={() => setListOpen(false)}><ArrowDropUpIcon/></button>
                </div>
                :
                <div className="flex flex-row">
                    <p className="mr-2">{closed? "Closed Today" : "Open Today "}</p>
                    <button onClick={() => setListOpen(true)}><p className="border-b border-weird-yellow hover:cursor-pointer">{closed? " " : "06:00 am - 11:00 pm"}</p></button>
                    <button className="text-puke align-start" onClick={() => setListOpen(true)}><ArrowDropDownIcon/></button>
                </div>

            }
        </div>
    </div>
  )
}

export default ConatctForm